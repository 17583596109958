<template>
  <div class="container-fluid">
    <div   class="backdrop">
      <p>
      PLEASE WAIT...
      </p>
    </div>
    <div class="row">
      <sidebar></sidebar>
      <nav-header></nav-header>

      <!-- MAIN CONTENT start -->
      <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10" >
        <breadcrumb :items="pageData.breadcrumb"></breadcrumb>
        
        <section class="px-sm-0 mx-sm-0 px-lg-5 mx-lg-5  mt-0 mt-md-5">
          <div v-if="!isOpen" class="container">
            <div class="row my-5">
              <div class="col-sm-12">
                <div class="card-primary h-100">
                  <div class="card-body d-flex align-items-center text-center">
                    <p>{{ $t('registration.registration-closed') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="container-fluid ">
            <div class="row justify-content-around mb-5 mt-md-5 mt-lg-0 pt-5 pt-sm-0">

              <!--
                  CARDS
               -->
              <!--<div class="col-sm-12 mb-0 mb-sm-4">
                <div class="card-primary h-100">
                  <div class="card-body d-flex align-items-center">
                    <h1 style="color: #fff">{{ $t('registration.registration-starts-at') }}</h1>
                  </div>
                </div>
              </div>-->
              <div class="col-sm-6 mb-4 mb-sm-0 ">
                <div class="card-primary h-100">
                  <div class="card-body d-flex align-items-center">
                    <p>{{ $t('registration.header-buttons.first-button') }}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="card-primary">
                  <div class="card-body">
                    <p>
                      {{ $t('registration.header-buttons.second-button') }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-12 mt-4" v-if="showThirdButton()">
                <div class="card-primary">
                  <div class="card-body">
                    <div>
                      <p>POLI4565 საბაკალავრო ნაშრომის მომზადება</p>
                    </div>
                    <div>
                      <p>POLI4220 საბაკალავრო ნაშრომის მომზადება</p>
                    </div>
                    <div>
                      <p>POLI4228 საბაკალავრო ნაშრომის მომზადება</p>
                    </div>
                    <div>
                      <p>PSYC1426 საბაკალავრო ნაშრომი ფსიქოლოგიაში</p>
                    </div>
                    <div>
                      <p>PSYC1420 საბაკალავრო ნაშრომი ფსიქოლოგიაში</p>
                    </div>
                    <div>
                      <p>JMMB3115 საბოლოო პროექტი ჟურნალისტიკაში</p>
                    </div>
                    <div>
                      <p>JMMB4240 საბოლოო პროექტი ჟურნალისტიკაში</p>
                    </div>
                    <div>
                      <p>COMM5145 საბოლოო პროექტი საზოგადოებასთან ურთიერთობაში</p>
                    </div>
                    <div>
                      <p>COMM4310 საბოლოო პროექტი საზოგადოებასთან ურთიერთობაში</p>
                    </div>
                    <div>
                      <div>
                        <p>&nbsp;</p>
                      </div>
                      <div>
                        <p><em>საგანზე დარეგისტრირება შეუძლიათ მხოლოდ დამამთავრებელ სემესტრზე მყოფ სტუდენტებს.</em></p>
                      </div>
                      <div>
                        <p>&nbsp;</p>
                      </div>
                      <div>
                        <p><em>იმისათვის, რომ სტუდენტმა დაასრულოს უნივერსიტეტის,&nbsp; უნდა აკმაყოფილებდეს&nbsp; პროგრამაში, სწავლების ორგანიზების თავისებურებებში მითითებულ პირობას.&nbsp;</em></p>
                      </div>
                      <div>&nbsp;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-5 mb-5 ">
              <div class="col text-center mt-5">
                <h1 v-if="guideLink" class="mb-4">
                  <a target="_blank" :href="guideLink">{{ $t('registration.message-to-students') }}</a>
                </h1>
                <h3>
                  <my-subjects-modal v-if="!user.must_choose_english" @subject-change="handleSubjectChange" @subject-remove="loadModules"></my-subjects-modal>
                  <must-change-subjects-modal v-if="!user.must_choose_english" @subject-change="handleSubjectChange" @subject-remove="loadModules"></must-change-subjects-modal>
                </h3>
                <h3 v-if="canChooseMinorProgram()">
                  <minor-programs-modal v-if="!user.must_choose_english" @change="minorProgramChanged($event)"></minor-programs-modal>
                </h3>
              </div>
            </div>

            <p @click="handleClickPreRegSubjects" class="text-center" style="font-size:20px; cursor:pointer; font-weight:bold;">  {{$t('registration.preRegSubject')}}</p>



            <p @click="writeProblem()" class="text-center" style="font-size:20px; cursor:pointer; font-weight:bold;color: red;"> {{ $t('registration.writeProblem') }}</p>


            <!--
                Unique and same values!!!
                --btn-close
                    href="#mcmodule_0" , data-target="#cmodule_0", aria-controls="cmodule_0"

                -- btn-registration
                    data-target="#cmodule_0", aria-controls="cmodule_0"

                --module-body
                    id="cmodule_0"
             -->
 


              

             <pre-reg-subjects></pre-reg-subjects>
             <write-problem></write-problem> 

            
            <groups-modal :wants-change="wantsChange"
                          @changed="changed"
                          @registered="loadModules"
                          @closed="handleModalClose"
                          :subject="chosenSubject"></groups-modal>


                          <change-pre-reg-subject  @closed="truncateValue()"  ></change-pre-reg-subject>

            <!-- modules -->
            <div class="row justify-content-start pt-5 pt-sm-0" id="modules">
              <sk-cards v-if="programModules.loading"
                        width-class="col-6"
                        :height="0.2"
                        :count="4"></sk-cards>
              <div v-else class="col-md-12 col-lg-6 mb-5 px-0 px-sm-4" v-for="(programModule, i) of programModules.data">
                <div :class="['module', { active: activeCollapses[i] }]">
                  <div class="module-header">
                    <!-- x button visible only on mobile -->
                    <div class="module-close text-right d-none mb-4 mt-2">
                      <a class="btn-close"  v-b-toggle="'cmodule_' + i" role="button">
                        <img src="/static/images/icons/registrations/X.png" alt="" class="img-fluid">
                      </a>
                    </div>
                    <button v-b-toggle="'cmodule_' + i" class="btn btn-registration" type="button">
                      {{ getProgramModuleName(programModule) }}
                      <span class="toggle-icon"></span>
                    </button>
                  </div>
                  <b-collapse v-model="activeCollapses[i]" @shown="loadSubjects(i)" :id="'cmodule_' + i" class="module-body">
                    <div class="registration-table">
                      <table class="table" v-if="programModule.subjects">
                        <thead>
                        <tr>
                          <th>{{ $t('registration.subject') }}</th>
                          <th>{{ $t('registration.evaluation') }}</th>
                          <th>{{ $t('registration.ects') }}</th>
                          <th>{{ $t('registration.semester') }}</th>
                          <th><span>&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <table v-for="(subjectBlock, blockName) of programModule.subjects">
                            <tr class="mt-2 mb-1" v-if="blockName !== '-'">
                              <td class="bg-red text-white text-center">
                                {{ blockName }}
                              </td>
                            </tr>
                            <tr v-if="!programModule.concentrationID || subject.concentrationID == programModule.concentrationID" v-for="(subject, s) of subjectBlock" @click="check_program_module_restriction(subject)">
                              <td>{{ subject.subject_code }} - {{ helpers.getLocalizedField(subject, 'subject') }}</td>
                              <td class="text-center">
                                <span v-if="subject.Eval">{{ subject.Eval }}</span>
                              </td>
                              <td class="text-center">
                                {{ subject.ECTS }}
                              </td>
                              <td class="text-center">
                                {{ helpers.getSubjectSemester(subject.subject_semester) }}
                              </td>
                              <td class="text-center">
                                <img v-if="canRemoveTaken(subject)" src="/static/images/icons/registrations/close.png" class="img-fluid">
                                <img v-else-if="canTake(subject)" src="/static/images/icons/registrations/open.png" class="img-fluid">
                              </td>
                            </tr>
                          </table>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                  <!--                  </div>-->
                </div>
              </div>
              <!-- Single module end -->

              <!-- Single module start -->
              <div v-if="isVisibleFreeCreditSubjects" class="col-md-12 col-lg-6 mb-5 px-0 px-sm-4">
                <div :class="['module', { active: isActiveFreeCreditSubjects }]">
                  <div class="module-header">
                    <!-- x button visible only on mobile -->
                    <div class="module-close text-right d-none mb-4 mt-2">
                      <a class="btn-close"  v-b-toggle="'free-credit'" role="button">
                        <img src="/static/images/icons/registrations/X.png" alt="" class="img-fluid">
                      </a>
                    </div>
                    <button v-b-toggle="'free-credit'" class="btn btn-registration" type="button">
                      {{ $t('registration.free-credit-subjects') }}
                      <span class="toggle-icon"></span>
                    </button>
                  </div>
                  <b-collapse v-model="isActiveFreeCreditSubjects" id="free-credit" class="module-body">
                    <div class="registration-table">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>{{ $t('registration.subject') }}</th>
                          <th>{{ $t('registration.evaluation') }}</th>
                          <th>{{ $t('registration.ects') }}</th>
                          <th><span>&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(subject, s) of freeCreditSubjects" @click="check_program_module_restriction(subject)">
                          <td>{{ subject.subject_code }} - {{ helpers.getLocalizedField(subject, 'subject') }}</td>
                          <td class="text-center">
                            <span v-if="subject.Eval">{{ subject.Eval }}</span>
                          </td>
                          <td class="text-center">
                            {{ subject.ECTS }}
                          </td>
                          <td class="text-center">
                            <img v-if="canRemoveTaken(subject)" src="/static/images/icons/registrations/close.png" class="img-fluid">
                            <img v-else-if="canTake(subject)" src="/static/images/icons/registrations/open.png" class="img-fluid">
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                  <!--                  </div>-->
                </div>
              </div>
              <!-- Single module end -->

              <!-- Single module start -->
              <div v-model="isActiveMinorSubjects" v-if="user && user.minorProgramID !== null && minorSubjects" class="col-md-12 col-lg-6 mb-5 px-0 px-sm-4">
                <div :class="['module', { active: isActiveMinorSubjects }]">
                  <div class="module-header">
                    <!-- x button visible only on mobile -->
                    <div class="module-close text-right d-none mb-4 mt-2">
                      <a class="btn-close"  v-b-toggle="'minor-subjects'" role="button">
                        <img src="/static/images/icons/registrations/X.png" alt="" class="img-fluid">
                      </a>
                    </div>
                    <button v-b-toggle="'minor-subjects'" class="btn btn-registration" type="button">
                      {{ $t('registration.minor-subjects') }}
                      <span class="toggle-icon"></span>
                    </button>


                 




                  </div>
                  <b-collapse id="minor-subjects" class="module-body">
                    <div class="registration-table">
                      <table class="table">
                        <thead>
                        <tr>
                          <th>{{ $t('registration.subject') }}</th>
                          <th>{{ $t('registration.evaluation') }}</th>
                          <th>{{ $t('registration.ects') }}</th>
                          <th>{{ $t('registration.semester') }}</th>
                          <th><span>&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
<!--                        <tr v-for="(subject, s) of minorSubjects" @click="onSubjectClick(subject)">-->
<!--                          <td>{{ subject.subject_code }} {{ helpers.getLocalizedField(subject, 'subject') }}</td>-->
<!--                          <td class="text-center">-->
<!--                            <span v-if="subject.Eval">{{ subject.Eval }}</span>-->
<!--                          </td>-->
<!--                          <td class="text-center">-->
<!--                            {{ subject.ECTS }}-->
<!--                          </td>-->
<!--                          <td class="text-center">-->
<!--                            {{ helpers.getSubjectSemester(subject.subject_semester) }}-->
<!--                          </td>-->
<!--                          <td class="text-center">-->
<!--                            <img v-if="canRemoveTaken(subject)" src="/static/images/icons/registrations/close.png" class="img-fluid">-->
<!--                            <img v-else-if="canTake(subject)" src="/static/images/icons/registrations/open.png" class="img-fluid">-->
<!--                          </td>-->
<!--                        </tr>-->
                        <tr>
                          <table v-for="(subjectBlock, blockName) of minorSubjects">
                            <tr class="mt-2 mb-1" v-if="blockName !== '-'">
                              <td class="bg-red text-white text-center">
                                {{ blockName }}
                              </td>
                            </tr>
                            <tr v-for="(subject, s) of subjectBlock" @click="check_program_module_restriction(subject)">
                              <td>{{ subject.subject_code }} - {{ helpers.getLocalizedField(subject, 'subject') }}</td>
                              <td class="text-center">
                                <span v-if="subject.Eval">{{ subject.Eval }}</span>
                              </td>
                              <td class="text-center">
                                {{ subject.ECTS }}
                              </td>
                              <td class="text-center">
                                {{ helpers.getSubjectSemester(subject.subject_semester) }}
                              </td>
                              <td class="text-center">
                                <img v-if="canRemoveTaken(subject)" src="/static/images/icons/registrations/close.png" class="img-fluid">
                                <img v-else-if="canTake(subject)" src="/static/images/icons/registrations/open.png" class="img-fluid">
                              </td>
                            </tr>
                          </table>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </b-collapse>
                  <!--                  </div>-->
                </div>
              </div>
              <!-- Single module end -->

            </div>
            <!-- modules end-->

          </div>
        </section>
   
      </main>
      <!-- MAIN CONTETN end -->
    </div>
  </div>
</template>

<script>
  import sidebar from '../components/navbar/sidebar';
  import sidebarSm from '../components/navbar/sidebar-sm';
  import appHeader from '../components/app-header';
  import pageHeader from '../components/page/page-header';
  import { mapGetters } from 'vuex';
  import NavHeader from '../components/navbar/nav-header';
  import Breadcrumb from '../components/page/breadcrumb';
  import PageTitle from '../components/page/page-title';
  import SkList from '../components/skeletons/sk-list';
  import MySubjectsModal from '../components/registration/my-subjects-modal';
  import MustChangeSubjectsModal from '../components/registration/must-change-subjects-modal';
  import MinorProgramsModal from '../components/registration/minor-programs-modal';
  import GroupsModal from '../components/registration/groups-modal';
  import PreRegSubjects from '../components/registration/PreRegSubjects';
  import ChangePreRegSubject from '../components/registration/ChangePreRegSubject';
  import SkCards from '../components/skeletons/sk-cards';
  import breadcrumb from '../components/page/breadcrumb';
  import WriteProblem from '../components/registration/WriteProblem.vue'

  export default {
    name: 'registration',
    components: { SkCards,
      GroupsModal,
      MySubjectsModal,
      MustChangeSubjectsModal,
      SkList,
      PageTitle,
      Breadcrumb,
      NavHeader,
      sidebar,
      sidebarSm,
      appHeader,
      pageHeader,
      MinorProgramsModal,
      breadcrumb,
      ChangePreRegSubject,
      PreRegSubjects,
      WriteProblem
    },

    data() {
      return {
        wantsChange: null,
        freeCreditSubjects: [],
        maxFreeCreditAmount: 0,
        takenFreeCreditAmount: 0,
        minorSubjects: null,
        chosenSubject: null,
        activeCollapses: [],
        isActiveFreeCreditSubjects: false,
        isActiveMinorSubjects: false,
        guideLink: '',
        isOpen: false,
      }
    },

    computed: {
      isVisibleFreeCreditSubjects() {
 
        //&& !this.user.must_choose_english
        return this.freeCreditSubjects.length > 0 || this.user.must_choose_english;
      },

      pageData() {
        return {
          title: this.$t('registration.title'),
          breadcrumb: [{
            icon: '',
            title: this.$t('home.title'),
            link: '/',
            isActive: false,
          },
            {
              icon: '',
              title: this.$t('registration.title'),
              link: '/registration',
              isActive: true,
            }],
        };
      },
      ...mapGetters({
        user: 'auth/user',
        programModules: 'registration/programModules',
        mustChangeGroups: 'registration/mustChangeGroups',
        mustChangePreRegSubject:'registration/mustChangePreRegSubject',
        changedPreRegSubject:'registration/changedPreRegSubject',
        preRegSubject:'registration/preRegSubject',
      })
    },

    watch: {
      user() {
        if(this.user.minorProgramID !== null) {
          this.loadMinorSubjects();
        }

        this.loadRegistrationGuide();
      },

      mustChangeGroups(val) {
        if(val.length > 0) {
          this.$bvModal.show('must-change-subjects-modal');
        }
      },

      
      mustChangePreRegSubject(val){

            if(val){ 
                this.$bvModal.show('change-pre-reg-subject');
            }


},

changedPreRegSubject(val){

  if(val){

     

       this.chosenSubject=val

       this.$bvModal.show('groups-modal');
  }

},

preRegSubject(val){

      if(val){

    
             
       this.chosenSubject=val

        this.$bvModal.show('groups-modal');


          this.$store.dispatch('registration/isRegistered',{subjectID:val.subjectID}).then(response=>{
              if(response.data.length>0){
                this.$store.dispatch('registration/setsubjectChangeButtonAppear',false)

              } 


          })


         
      }



}


    },

    mounted() {


    



      if (navigator.appVersion.indexOf("Chrome/") != -1) {
        this.root = document.documentElement;
        this.root.style.setProperty('--scroll', 'scroll');
        this.root.style.setProperty('--auto', 'auto');
      }

      this.loadModules();

      if(this.mustChangeGroups.length > 0) {
        setTimeout(() => {
          this.$bvModal.show('must-change-subjects-modal');
        }, 1000);
      }
    },

    methods: {


      writeProblem(){

        this.$bvModal.show('write-problem-modal');

      },


      truncateValue(){

        this.$store.dispatch('registration/setmustChangePreRegSubject',null)

     
      },


      handleClickPreRegSubjects(){

        this.$bvModal.show('pre-reg-modal');



      },



      showThirdButton() {
        const programIDs = [
          74,
          76,
          121,
          123,
          126,
          128,
          245,
          247,
          248,
          258,
          266,
          341
        ];

        return programIDs.indexOf(parseInt(this.user.programID)) > -1;
      },

      canChooseMinorProgram() {
        const subjects = []
        for (const block in this.minorSubjects) {
          for (const s of this.minorSubjects[block])
            subjects.push(s)
        }

      if(subjects.filter(o => o.Eval !== null).length > 0) return false;

        return this.user.ects <= 180 && this.user.studyLevel === 1;
      },

      handleSubjectChange(group) {
        this.$store.dispatch('registration/getSubject', group.subjectID)
        .then(response => {
          this.chosenSubject = response.data.data;

          this.wantsChange = group.group;
          this.$bvModal.show('groups-modal');
        });
      },

      handleModalClose() {
        this.chosenSubject = null;
        this.wantsChange = null;
      
      },

    
       

      loadMinorSubjects() {
        this.$store.dispatch('registration/loadMinorSubjects')
          .then(response => {
            this.minorSubjects = response.data.data;
          })
      },

      loadFreeCreditSubjects() {
        this.$store.dispatch('registration/loadFreeCreditSubjects')
          .then(response => {
            this.freeCreditSubjects = response.data.data.subjects;
            this.maxFreeCreditAmount = parseInt(response.data.data.max_credit_amount);
            this.takenFreeCreditAmount = parseInt(response.data.data.taken_credit_amount);
          });
      },

      changed() {
        this.wantsChange = null;
        this.$store.dispatch('registration/loadAlreadyRegistered');
      },

      loadModules() {
        this.$store.dispatch('registration/loadProgramModules').then(() => {
          this.isOpen = true;

          if(this.user.studyLevel == 1) this.loadFreeCreditSubjects();

          if(this.user && this.user.minorProgramID !== null) this.loadMinorSubjects();

          for (const i in this.activeCollapses) {
            if (this.activeCollapses[i]) this.loadSubjects(i)
          }
        });
      },

      getProgramModuleName(programModule) {
        if (programModule.concentrationID) {
          return this.helpers.getLocalizedField(programModule, 'program_module') + ' - ' + this.helpers.getLocalizedField(programModule, 'concentration')
        }
        return this.helpers.getLocalizedField(programModule, 'program_module')
      },

      minorProgramChanged(programID) {
        if(!programID) {
          this.minorSubjects = [];
        } else {
          this.$store.dispatch('registration/loadMinorSubjects')
          .then(response => {
            this.minorSubjects = response.data.data;
          })
        }
      },



      check_program_module_restriction(subject){

        

        this.$store.dispatch('registration/program_module_restrictions',{program_moduleID:subject.program_moduleID?subject.program_moduleID:0}).then(response=>{

            if(response.data.length>0){
             

              this.$swal({
                  title: "",
                  text: response.data[0].text,
                  type: "warning",
                  showCancelButton: true,
                  confirmButtonText: this.$t('registration.minor-modal.ok'),
                  cancelButtonText: this.$t('registration.minor-modal.cancel'),
                  closeOnConfirm: false,
                  closeOnCancel: false
                })
          .then((result) => {
            if (result && result.isConfirmed) {
              this.onSubjectClick(subject)
            }else {
              if(subject.nomeri=='free'){

               this.$store.dispatch('registration/dontWantFromFreeCredit')

              }


            }
          });





            }else {

                  this.onSubjectClick(subject)

            }


         
        })

         
      },

      onSubjectClick(subject) {
 

        if(this.canRemoveTaken(subject)) {
          this.removeTaken(subject);
        } else if(!this.canRemoveTaken(subject) && this.canTake(subject) && this.isAlreadyTaken(subject)) {
          this.alreadyTaken(subject);
        } else if (!this.canRemoveTaken(subject) && this.canTake(subject)) {
          this.take(subject);
        }   
        else {
          this.helpers.notifyMessage(this.$t('registration.subject-is-not-open'))
        }
      },

      isAlreadyTaken(subject) {
        return this.canTake(subject) && subject.Eval !== null && subject.Eval !== 'F';
      },

      canRemoveTaken(subject) {
        return parseInt(subject.saganiID_registred) === 1;
      },

      canTake(subject) {
        return parseInt(subject.saganiID_registredbase) === 1;
      },

      alreadyTaken(subject) {
        this.$swal({
          title: "",
          text: this.$t('registration.already-taken-warning'),
          type: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t('registration.minor-modal.ok'),
          cancelButtonText: this.$t('registration.minor-modal.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        })
          .then((result) => {
            if (result && result.isConfirmed) {
              this.take(subject);
            }
          });
      },

      take(subject) {
        this.chosenSubject = subject;
        this.$bvModal.show('groups-modal');
      },

      removeTaken(subject) {
        this.$swal({
          title: "",
          text: this.$t('registration.remove-taken-warning'),
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: this.$t('registration.minor-modal.ok'),
          cancelButtonText: this.$t('registration.minor-modal.cancel'),
          closeOnConfirm: false,
          closeOnCancel: false
        })
          .then((result) => {
            if (result && result.isConfirmed) {
              this.removeTakenSubject(subject);
            }
          });
      },

      removeTakenSubject(subject) {
        this.$store.dispatch('registration/removeSubject', subject.subjectID)
          .then((response) => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_removed'));
            this.loadModules();
          })
      },

      loadSubjects(programModuleIdx) {
        this.$store.dispatch('registration/loadProgramModuleSubjects', programModuleIdx)
      },

      loadRegistrationGuide() {
        this.$store.dispatch('registration/loadRegistrationGuide', this.user.programID)
        .then(response => {
          this.guideLink = response.data.data;
        })
      }
    }
  };
</script>

<style>
  .backdrop{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.199);
    top: 0;
    display: none;
    z-index: 999999999999;
  }


</style>
