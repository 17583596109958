<template>

    <div>

        <b-modal  
  @shown="onShown()"
 :no-close-on-esc="false"
 :no-close-on-backdrop="false"
  :hide-header="true"
  :hide-footer="true"
  content-class="modal-blue"
  id="ten-minutes-matching-modal"
  size="xl"
  scrollable
  centered>
    <!-- Modal content-->
   
   
    <div class="row ">
      <div class="col mb-5 ">
            
        <h2 class="text-center">On this day, you have two lectures in different buildings, with a small time difference between them. You can delete one of the listed subjects.</h2>

        <table class="table" style="font-size: 12px; width: 80% !important;"> 

            <tr>

            <td>day</td>
            <td>subject_code_first</td>
            <td>subject_first</td> 
            <td>rooms_first</td> 
            <td>time_first</td>
            <td>time_end_first</td>
             <td></td>   
            <td>subject_code_second</td>
            <td>subject_second</td>
            <td>rooms_second</td> 
            <td>time_second</td>
            <td>time_end_second</td>
            <td></td>   
            </tr>

                <tr v-for="getData of data">
                    <td>{{ day[getData.dayID] }} </td>
                    <td>{{ getData.subject_code_first }} </td>
                    <td>{{ getData.subject_first_eng }}</td> 
                    <td>{{ getData.rooms_first  }}</td> 
                    <td>{{ getData.time_first  }}</td>
                    <td>{{ getData.time_end_first  }}</td>
                    <td @click="removeTakenSubject(getData.subject_ID_first)" style="cursor: pointer; color: red; font-size: 20px;">X</td>
                    <td>{{ getData.subject_code_second  }}</td>
                    <td>{{ getData.subject_second_eng  }}</td>
                    <td>{{ getData.rooms_second  }}</td>
                    <td>{{ getData.time_second  }}</td>
                    <td>{{ getData.time_end_second  }}</td>
                    <td @click="removeTakenSubject(getData.subject_ID_second)" style="cursor: pointer; color: red; font-size: 20px;">X</td>
                </tr>

            </table>

           
             
            <div class="row">
      <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
        <button   type="button" class="btn btn-light-bold" @click="hideModal()">Keep All Groups</button>
      </div>
    </div>
      </div>
    </div>






 
</b-modal>


    </div>



</template>
<script>
export default{

    props:['data'],

        data(){

            return {

                    day:["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday","Sunday"]


            }

        },


        methods:{

            removeTakenSubject(subjectID) {
                if(confirm("Are you sure that you want remove this subject?")){
        this.$store.dispatch('registration/removeSubject', subjectID)
          .then((response) => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_removed'));
            setTimeout(()=>{
                location.reload()
            },2000)
          })
        }
      },

           

            onShown(){
 

            },
            hideModal() {
                this.$bvModal.hide('ten-minutes-matching-modal');
                 this.$emit('closed');

            },

          
        }

}

</script>
<style>
 
    .modal-xl {
        max-width: 1500px  !important;
    }
 
</style>
